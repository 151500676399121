import { MOSCOW_TZ_OFFSET } from '../../constants';

export function getMoscowDate(timestamp: Date = new Date()): string {
  return new Date(+timestamp + MOSCOW_TZ_OFFSET).toISOString().slice(0, 10);
}

export function getMoscowDateWithTime(timestamp: Date = new Date()): string {
  const isoDate = new Date(+timestamp + MOSCOW_TZ_OFFSET).toISOString();

  const year = isoDate.slice(0, 4);
  const month = isoDate.slice(5, 7);
  const day = isoDate.slice(8, 10);
  const hours = isoDate.slice(11, 13);
  const minutes = isoDate.slice(14, 16);

  return `${day}.${month}.${year}, ${hours}:${minutes}`;
}
