import { ProductsSortTypes } from './contract/services/enums';

export const productsSortOptions = [
  {
    value: ProductsSortTypes.DEFAULT,
    label: 'По умолчанию',
  },
  {
    value: ProductsSortTypes.NAME,
    label: 'По названию',
  },
  {
    value: ProductsSortTypes.VIEW_COUNT,
    label: 'Показы',
  },
  {
    value: ProductsSortTypes.CLICK_COUNT,
    label: 'Клики',
  },
  {
    value: ProductsSortTypes.CTR,
    label: 'CTR',
  },
  {
    value: ProductsSortTypes.CPC,
    label: 'CPC',
  },
  {
    value: ProductsSortTypes.SPENT,
    label: 'Расход',
  },
];

export const MOSCOW_TZ_OFFSET = 3 * 60 * 60 * 1000;
