import { CampaignLogTypes } from '../services/enums';

export const getCampaignLogTypeName = (type: CampaignLogTypes): string => {
  switch (type) {
    case CampaignLogTypes.CAMPAIGN_CHANGED_AUTO_DEPOSIT:
      return 'Автопополнение';
    case CampaignLogTypes.CAMPAIGN_CHANGED_CPM:
      return 'Изменение ставки';
    case CampaignLogTypes.CAMPAIGN_CHANGED_STATUS:
      return 'Изменение статуса кампании';
    case CampaignLogTypes.CAMPAIGN_DEPOSIT:
      return 'Ручное пополнение';
    case CampaignLogTypes.CAMPAIGN_PRODUCT_CHANGED_MAX_CPM:
      return 'Изменение макс. ставки';
    case CampaignLogTypes.CAMPAIGN_PRODUCT_CHANGED_TARGET_POSITION:
      return 'Изменение цел. позиции';
    case CampaignLogTypes.CAMPAIGN_PRODUCT_CHANGED_USE_BID_MANAGER:
      return 'Изменение статуса биддера';
    default:
      return type;
  }
};
