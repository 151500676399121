const NO_RESULT = '–';

export enum ChangeStatus {
  INCREASE = 'increase',
  STILL = 'still',
  DECREASE = 'decrease',
}
export const POSITIONS_NUMBER_VALUE = new Map<number, number>([
  [99991, 1949],
  [99992, 2949],
  [99993, 3949],
  [99995, 5949],
  [99996, 6949],
  [99997, 6949],
  [99998, 6949],
  [99999, 6949],
]);
export const DAILY_POSITIONS = new Map<number, string>([
  [99991, '1К+'],
  [99992, '2К+'],
  [99993, '3К+'],
  [99995, '5К+'],
  [99996, '6К+'],
  [99997, '6К+'],
  [99998, '6К+'],
  [99999, '6К+'],
  [Infinity, NO_RESULT],
]);

export const CHANGE_COLORS = new Map<ChangeStatus, 'red' | 'blue' | 'green'>([
  [ChangeStatus.INCREASE, 'red'],
  [ChangeStatus.STILL, 'blue'],
  [ChangeStatus.DECREASE, 'green'],
]);
