import { FORMATTER_LANG, NO_RESULT } from '../constants';

const DATE_FORMATTER = new Intl.DateTimeFormat(FORMATTER_LANG, {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
});

const DATETIME_FORMATTER = new Intl.DateTimeFormat(FORMATTER_LANG, {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
});

const DATE_LONG_FORMATTER = new Intl.DateTimeFormat(FORMATTER_LANG, {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
});

const DATETIME_LONG_FORMATTER = new Intl.DateTimeFormat(FORMATTER_LANG, {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
});

// Форматтеры с днем недели
const DATE_WITH_WEEKDAY_FORMATTER = new Intl.DateTimeFormat(FORMATTER_LANG, {
  weekday: 'short',
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
});

const DATETIME_WITH_WEEKDAY_FORMATTER = new Intl.DateTimeFormat(FORMATTER_LANG, {
  weekday: 'short',
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
});

const DATE_LONG_WITH_WEEKDAY_FORMATTER = new Intl.DateTimeFormat(FORMATTER_LANG, {
  weekday: 'short',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
});

const DATETIME_LONG_WITH_WEEKDAY_FORMATTER = new Intl.DateTimeFormat(FORMATTER_LANG, {
  weekday: 'short',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
});

export function formatDate(
  date: unknown,
  withTime?: boolean,
  long?: boolean,
  withWeekday?: boolean,
): string {
  date = +new Date(date as any);

  if (!isNumber(date)) {
    return NO_RESULT;
  }

  let dateFormatter: Intl.DateTimeFormat;

  if (withWeekday) {
    dateFormatter = long
      ? withTime
        ? DATETIME_LONG_WITH_WEEKDAY_FORMATTER
        : DATE_LONG_WITH_WEEKDAY_FORMATTER
      : withTime
        ? DATETIME_WITH_WEEKDAY_FORMATTER
        : DATE_WITH_WEEKDAY_FORMATTER;
  } else {
    dateFormatter = long
      ? withTime
        ? DATETIME_LONG_FORMATTER
        : DATE_LONG_FORMATTER
      : withTime
        ? DATETIME_FORMATTER
        : DATE_FORMATTER;
  }

  return dateFormatter.format(date).replace('г.', '');
}
