import { isAfter, isBefore } from 'date-fns';

export function checkInterval(date: Date, start: Date | null, end: Date | null): boolean {
  if (start && end) {
    return !isBefore(date, start) && !isAfter(date, end);
  }

  if (start) {
    return !isBefore(date, start);
  }

  if (end) {
    return !isAfter(date, end);
  }

  return true;
}
