import { FORMATTER_LANG, NO_RESULT } from '../constants';

const NUMBER_FORMATTERS = new Map<number, Intl.NumberFormat>();

export function formatNumber(value: unknown, digits: number = 0): string {
  if (!NUMBER_FORMATTERS.has(digits)) {
    NUMBER_FORMATTERS.set(
      digits,
      new Intl.NumberFormat(FORMATTER_LANG, { maximumFractionDigits: digits }),
    );
  }

  return isNumber(value) ? NUMBER_FORMATTERS.get(digits)!.format(value) : NO_RESULT;
}
